import { Typography } from "@mui/material";
// import Layout from '@/components/public/page-architecture';
Custom404.getLayout = (page?: React.ReactNode) => <>{page}</>;
export default function Custom404() {
  return (
    <Typography textAlign="center" variant="h3" py={10}>
      404 - Page Not Found
    </Typography>
  );
}
